<template>
  <div>
    <util-date-type-picker />
    <util-date-range-picker />

    <div class="cui__utils__heading">
      <strong>Card Personalization Report</strong>
    </div>

    <b-card no-body>
      <!-- User Interface controls -->
      <b-card-header class="bg-light">
        <b-row>
          <b-col md="8" xl="5">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col md="4" xl="2" class="offset-xl-5">
            <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
              <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <!-- Main table element -->
      <b-table
        class="card-table"
        show-empty
        stacked="lg"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template slot="name" slot-scope="row">{{ row.value.first }} {{ row.value.last }}</template>

        <template slot="isActive" slot-scope="row">{{ row.value ? 'Yes :)' : 'No :(' }}</template>

        <template slot="actions" slot-scope="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >Info modal</b-button>
          <b-button
            size="sm"
            @click="row.toggleDetails"
          >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-card-footer>
    </b-card>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>

  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangePicker from './DateRangePickerHelper'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'CardPersonalizationReport', // TableBootstrapComplete
  components: {
    'util-date-type-picker': DateTypePicker,
    'util-date-range-picker': DateRangePicker,
  },
  data() {
    return { // isActive: true/false, _rowVariant: 'success', _cellVariants: { age: 'danger', isActive: 'warning' },
      items: [
        /* { isActive: true, age: 40, name: { first: 'Dickerson', last: 'Macdonald' } },
        {
          isActive: false,
          age: 9,
          name: { first: 'Mini', last: 'Navarro' },
          _rowVariant: 'success',
        },
        {
          isActive: true,
          age: 87,
          name: { first: 'Larsen', last: 'Shaw' },
          _cellVariants: { age: 'danger', isActive: 'warning' },
        }, */
      ],
      fields: [
        { key: 'date', label: 'Order Date', tdClass: 'td-lg-shrink text-nowrap', thClass: 'td-lg-shrink text-nowrap', sortable: true, sortDirection: 'desc' },
        { key: 'card', label: 'Card', tdClass: 'td-lg-shrink text-nowrap', sortable: true },
        { key: 'sender', label: 'Sender', tdClass: 'td-lg-shrink text-nowrap' },
        { key: 'recipient', label: 'Recipient', tdClass: 'td-lg-shrink text-nowrap' },
        { key: 'message', label: 'Message' },
        { key: 'sentiment', label: 'Sentiment', sortable: true }, // , class: 'text-center'
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'globalDateRange',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },

  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    triggerUpdateChart() {
      this.startLoading()
      const selectFilter = '(count,total,hits.(data.(creation_date,customer_info.(customer_name,customer_no),' +
        'product_items.(product_id,quantity,c_hbc_personalizationMessage,c_sentiment),' +
        'shipments.(shipping_address.(full_name)))))'

      this.fetchOrderedProductDetails({
        saveToStateObj: 'setOrderedProductsDetails',
        selectFilter: selectFilter,
        dateType: this.globalDateTypeSelected,
        fromDate: this.globalDateRange.fromDate,
        toDate: this.globalDateRange.toDate,
      })
        .then(() => this.updateChartCardPersonalization(this.processSummaryReport(this.orderedProductsDetails)))
        .then(() => this.stopLoading())
    },

    processSummaryReport(summaryReport) {
      const personalizationDetails = []
      const orders = summaryReport || []

      orders.forEach((order) => {
        const prodItems = order.data.product_items || []
        prodItems.forEach((prodItem) => {
          personalizationDetails.push({
            date: this.formatUTCtoLocal(order.data.creation_date),
            card: prodItem.product_id,
            sender: order.data.customer_info.customer_name,
            recipient: order.data.shipments[0].shipping_address.full_name,
            message: prodItem.c_hbc_personalizationMessage,
            sentiment: prodItem.c_sentiment
          })
        })
      })

      return personalizationDetails
    },

    updateChartCardPersonalization(personalizationDetails) {
      this.items = personalizationDetails
      this.totalRows = personalizationDetails.length
    },

    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleDateString()
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
